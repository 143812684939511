body {
  /*background-image: url('/tileable-background.png');*/
  background-size: auto;
  background-repeat: repeat;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

input, textarea {
  background-color: white; /* Ensure textboxes have a white background */
  color: black; /* Ensure text is black for readability */
  border: 1px solid #ccc; /* Add a border */
  padding: 10px; /* Add padding for better spacing */
  margin-bottom: 10px; /* Add some margin for spacing between elements */
  width: 100%; /* Make input fields full width */
  box-sizing: border-box; /* Ensure padding and borders are included in the element's total width and height */
}

button {
  background-color: #1976d2; /* Use a primary color for buttons */
  color: white; /* Ensure text is white for readability */
  border: none; /* Remove default border */
  padding: 10px 20px; /* Add padding for better spacing */
  cursor: pointer; /* Change cursor to pointer on hover */
}

button:hover {
  background-color: #155a9b; /* Darken button color on hover */
}
